.image-container {
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.link {
  text-decoration: underline;
  color: #000;
}

@media (min-width:1400px) {
  .image-container {
    width: 50%;
  }
}

/* #region header-white */

.header-white-container {
  height: fit-content;
  border-bottom: 1px solid #000000;
  background-color: #FFF;
  position: relative;
}

.header-white-container-text {
  width: 59%;
  margin: 0 auto;
  text-align: justify;
  user-select: none;
}

.header-white-container-text p {
  font-size: 60px;
}

.header-white-image-container {
  border-left: 1px solid #2c2c2c;
  border-right: 1px solid #2c2c2c;
  border-top: 50px solid #2c2c2c;
  border-radius: 20px 20px 0 0;
  width: 50%;
  margin: 0 auto;
  position: relative;
}

.header-white-image-container img {
  width: 100%;
}

.header-white-contato-container {
  background-color: #FFF;
  width: 500px;
  max-width: 500px;
  position: absolute;
  top: 60%;
  right: 70px;
  transform: translateY(-50%);
  border-radius: 5px;
  padding: 25px 20px 10px 35px;
  font-size: 16px;
}

.contato-top {
  color: #605f5f;
  font-size: 19px;
  margin-bottom: 2px !important;
}

.contato-form {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.contato-form-input {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin-bottom: 20px;
}

.contato-form-input input {
  padding: 8px 10px;
  font-size: medium;
  border-radius: 4px;
  outline: none;
  border: 1px solid #aaa9a9;
}

.contato-form-button {
  position: relative;
}

.contato-form button {
  border: none;
  background-color: #d17a4d;
  color: #FFF;
  padding: 8px 30px;
  font-size: 18px;
  border-radius: 4px;
  position: absolute;
  bottom: 20px;
}

.contato-bottom {
  color: #605f5f;
  font-size: 17px;
  width: 85%;
}

/* #endregion header-white */

/* #region escolher */

.escolher-container {
  background-color: #d17a4d;
  height: fit-content;
  border-bottom: 1px solid #000000;
}

.escolher-title-container h1 {
  color: #000;
  padding: 150px 0 0 400px;
  font-size: 60px;
}

.escolher-content-container {
  font-family: Arial, Helvetica, sans-serif;
  color: #000;
  display: flex;
  flex-direction: row;
  width: 80%;
  margin: 20px auto 0 auto;
  justify-content: center;
}

.escolher-image-container {
  width: 100%;
  border-left: 1px solid #2c2c2c;
  border-right: 1px solid #2c2c2c;
  border-top: 50px solid #2c2c2c;
  border-radius: 20px 20px 0 0;
}

.escolher-image-container img {
  width: 100%;
}

.escolher-text-container {
  width: 50%;
  padding: 40px 20px 0 70px;
  font-size: 22px;
}

/* #endregion escolher */

/* #region explore */
.explore-container {
  background-color: #f9ca62;
  height: fit-content;
  border-bottom: 1px solid #000000;
}

.explore-title-container h1 {
  color: #000;
  padding: 150px 0 0 400px;
}

.explore-title-container h1 {
  font-size: 60px;
}

.explore-content-container {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  color: #000;
  display: flex;
  flex-direction: row;
  width: 80%;
  margin: 48px auto 0 auto;
}

.explore-image-container {
  height: fit-content;
  width: 100%;
  border-left: 1px solid #2c2c2c;
  border-right: 1px solid #2c2c2c;
  border-top: 50px solid #2c2c2c;
  border-radius: 20px 20px 0 0;
}

.explore-image-container img {
  width: 100%;
}

.explore-text-container {
  width: 50%;
  line-height: 1.3cm;
  padding: 50px 44px 0 70px;
  font-size: 22px;
}

/* #endregion explore */

/* #region beneficio */
.beneficio-container {
  background-color: #f9ca62;
  height: fit-content;
  border-bottom: 1px solid #000000;
  padding-bottom: 30px;
}

.beneficio-title-container h1 {
  color: #000;
  padding: 40px 0 0 400px;
}

.beneficio-title-container h1 {
  font-size: 60px;
}

.beneficio-content-container {
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: row;
  width: 80%;
  margin: 48px auto 0 auto;
}

.beneficio-text-container {
  padding: 50px 44px 0 70px;
  font-size: 22px;
}

.beneficio-text-container .bottom-text {
  line-height: 1cm;
}

/* #endregion beneficio */

/* #region versatilidade */
.versatilidade-container {
  background-color: #efefef;
  height: fit-content;
  display: flex;
  padding: 40px 0;
  flex-direction: column;
  align-items: center;
}

.versatilidade-container h1 {
  font-size: 50px;
  margin-bottom: 50px;
}

.carrossel-container {
  position: relative;
}

.scroll-container {
  width: 98vw;
  display: flex;
  overflow-x: auto;
  padding: 20px;
  scrollbar-width: thin;
}

.scroll-container::-webkit-scrollbar {
  height: 8px;
}

.scroll-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

.card-item {
  flex: 0 0 auto;
  margin: 0 10px;
}

.card {
  width: 400px;
  height: 500px;
  padding: 15px;
}

.carousel-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.8) !important;
  color: #000 !important;
  border: none;
}

.btn-primary:hover:not(.btn-active):hover {
  background-color: #FFF !important;
}

.btn-primary:hover .btn-active:hover {
  background-color: #FFF !important;
}

.btn.btn-primary:focus:not(.btn-active) {
  background-color: #efefef !important;
}

.left {
  left: 10px;
}

.right {
  right: 10px;
}

.ribbon {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}

.ribbon span {
  font-size: 10px;
  font-weight: bold;
  color: #FFF;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  width: 100px;
  display: block;
  background: #bf0316;
  background: linear-gradient(#bb2938 0%, #9c0312 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px;
  left: -21px;
}

.ribbon span::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #bf0316;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #bf0316;
}

.ribbon span::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border-right: 3px solid #bf0316;
  border-left: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #bf0316;
}

/* #endregion versatilidade */

/* #region personalize */
.personalize-container {
  height: fit-content;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #000000;
  padding: 30px 0;
}

.personalize-text-container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
}

.personalize-text-container p {
  width: 70%;
  font-size: 50px;
}

.personalize-button-container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.personalize-button-container button {
  background-color: #56bd90;
  border: none;
  border-radius: 4px;
  font-size: 50px;
  font-weight: 500;
  padding: 0 60px;
}

/* #endregion personalize */

/* #region conheca */
.conheca-container {
  background-color: #559cbb;
  height: fit-content;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #000000;
  padding: 30px 0;
}

.conheca-text-container p {
  font-size: 50px;
  color: #000;
  margin-bottom: 50px;
}

.conheca-button-container button {
  background-color: #000;
  color: #FFF;
  border: none;
  border-radius: 4px;
  font-size: 45px;
  font-weight: 500;
  padding: 0 60px;
}

.conheca-button-container a {
  color: #FFF;
}

/* #endregion conheca */

/* #region cenarios */

.cenarios-container {
  background-color: #FFF;
  height: fit-content;
  border-bottom: 1px solid #000000;
}

.cenarios-title-container h1 {
  width: 80%;
  color: #000;
  padding: 150px 0 0 400px;
}

.cenarios-title-container h1 {
  font-size: 60px;
}

.cenarios-content-container {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  color: #000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 80%;
  margin: 35px auto 0 auto;
  padding: 0 0 80px 0;
}

.cenarios-image-container {
  height: fit-content;
  width: 100%;
  border-left: 1px solid #2c2c2c;
  border-right: 1px solid #2c2c2c;
  border-bottom: 1px solid #2c2c2c;
  border-top: 50px solid #2c2c2c;
  border-radius: 20px 20px 0 0;
}

.cenarios-image-container img {
  width: 100%;
}

.cenarios-accordion-container {
  width: 100%;
  padding: 50px 0 0 20px;
  font-size: 22px;
}

.accordion-header button {
  background-color: #FFF;
  border: none;
  font-size: 20px;
}

.accordion-item {
  border-radius: 0 !important;
  border: none;
  border-bottom: 1px solid #000;
}

.accordion-body {
  color: #000;
  font-size: 16px;
  text-align: justify;
}


.accordion-button:not(.collapsed) {
  color: #000;
  background-color: #FFF;
  border-radius: 0;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-radius: 0;
}

/* #endregion cenarios */

/* #region informe */
.informe-container {
  background-color: #56bd90;
  height: fit-content;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #000000;
  padding: 30px 0;
}

.informe-text-container p {
  font-size: 50px;
  color: #000;
  margin-bottom: 50px auto;
  padding: 0 100px;
}

.informe-button-container button {
  background-color: #000;
  color: #FFF;
  border: none;
  border-radius: 4px;
  font-size: 45px;
  font-weight: 500;
  padding: 0 60px;
}

/* #endregion informe */

/* #region ajuda */
.ajuda-container {
  background-color: #efefef;
  height: 750px;
  position: relative;
}

.ajuda-form-container {
  background-color: #FFF;
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 93%;
  height: 580px;
}

.ajuda-form {
  background-color: #559cbb;
  width: 60%;
  padding: 50px;
  display: flex;
  flex-direction: column;
}

.ajuda-form form {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
}

.ajuda-form h1 {
  color: #FFF;
  font-size: 40px;
}

.ajuda-form input {
  border: none;
  outline: none;
  margin: 10px 0;
  padding: 7px 10px;
  font-size: 16px;
  border-radius: 5px;
}

.ajuda-form textarea {
  border: none;
  outline: none;
  margin: 10px 0;
  padding: 7px 10px;
  font-size: 16px;
  border-radius: 5px;
  resize: none;
}

.ajuda-form button {
  color: #FFF;
  background-color: #000;
  border: none;
  margin-top: 30px;
  font-size: 20px;
  padding: 5px 0;
  border-radius: 5px;
}

.ajuda-decoration {
  background-color: #000;
  height: 120px;
  width: 7%;
  position: absolute;
  bottom: 0;
}

.ajuda-contato {
  word-break: break-all;
  font-size: 16px;
  width: 40%;
  padding: 130px 20px;
}

.ajuda-contato p {
  user-select: auto;
  margin-bottom: 20px;
}

.ajuda-contato hr {
  width: 17%;
}

/* #endregion ajuda */

/* #region footer */
.footer {
  height: 400px;
  background-color: #000;
  position: relative;
}

.footer-logo {
  position: absolute;
  top: 30px;
  right: 0;
  width: 93%;
}

.footer-infos {
  position: absolute;
  top: 140px;
  left: 7%;
  width: 56%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #FFF;
}

.infos-card {
  width: 200px;
}

.footer-info-title {
  color: #559cbb;
}

.infos-card hr {
  width: 50%;
}

/* #endregion footer */

/* #region ------------------------------------------------------media---------------------------------------------- */

@media (max-width: 575.98px) {

  /* #region header-white */

  .header-white-container-text {
    padding-top: 15px;
    width: 90%;
  }

  .header-white-container-text p {
    font-size: 25px;
    text-align: start;
  }

  .header-white-image-container {
    width: 90%;
  }

  .header-white-image-container img {
    width: 100%;
  }


  .header-white-contato-container {
    width: 100%;
    background-color: #efefef;
    position: relative;
    top: initial;
    right: initial;
    transform: inherit;
    border-radius: 5px;
    padding: 10px;
  }

  .contato-top {
    color: #605f5f;
    font-size: 18px;
    margin-bottom: 2px !important;
  }

  .contato-form-input {
    width: 62%;
  }

  .contato-bottom {
    font-size: 14px;
    width: 100%;
  }

  /* #endregion header-white */

  /* #region escolher */


  .escolher-title-container h1 {
    padding: 80px 5px 0 10px;
  }

  .escolher-title-container h1 {
    font-size: 45px;
  }

  .escolher-content-container {
    flex-direction: column;
    width: 95%;
  }

  .escolher-image-container {
    height: fit-content;
    border-bottom: 1px solid #2c2c2c;
  }

  .escolher-image-container img {
    width: 100%;
  }

  .escolher-text-container {
    padding: 30px 20px 0 20px;
    font-size: 18px;
    width: 100%;
  }

  /* #endregion escolher */

  /* #region explore */
  .explore-title-container h1 {
    padding: 80px 5px 0 10px;
  }

  .explore-title-container h1 {
    font-size: 45px;
  }

  .explore-content-container {
    flex-direction: column;
    width: 95%;
    margin: 20px auto 0 auto;
  }

  .explore-image-container {
    height: 100%;
  }

  .explore-image-container img {
    width: 100%;
  }

  .explore-text-container {
    width: 100%;
    line-height: 1cm;
    padding: 30px 20px 0 20px;
    font-size: 18px;
  }

  /* #endregion explore */

  /* #region beneficio */

  .beneficio-title-container h1 {
    padding: 80px 5px 0 10px;
  }

  .beneficio-title-container h1 {
    font-size: 45px;
  }

  .beneficio-content-container {
    width: 95%;
    margin: 20px auto 0 auto;
  }

  .beneficio-text-container {
    line-height: normal;
    padding: 10px;
    font-size: 18px;
  }

  .beneficio-text-container .bottom-text {
    line-height: normal;
  }

  /* #endregion beneficio */

  /* #region versatilidade */
  .versatilidade-container {
    padding-top: 40px;
  }

  .versatilidade-container h1 {
    padding: 0 5px 0 10px;
    font-size: 45px;
    margin-bottom: 30px;
  }

  .scroll-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
    padding: 0;
  }

  .card {
    width: 95%;
    height: fit-content;
    padding: 15px;
    margin: 10px 0;
  }

  .carousel-control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.8) !important;
    color: #000 !important;
    border: none;
  }

  .btn-primary:hover:not(.btn-active):hover {
    background-color: #FFF !important;
  }

  .btn-primary:hover .btn-active:hover {
    background-color: #FFF !important;
  }

  .btn.btn-primary:focus:not(.btn-active) {
    background-color: #efefef !important;
  }

  .left {
    left: 10px;
  }

  .right {
    right: 10px;
  }



  /* #endregion versatilidade */

  /* #region personalize */
  .personalize-container {
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
  }

  .personalize-text-container {
    width: 95%;
  }

  .personalize-text-container p {
    width: 100%;
    font-size: 35px;
  }

  .personalize-button-container {
    width: 95%;
  }

  .personalize-button-container button {
    width: 100%;
    font-size: 40px;
    padding: 0 10px;
  }

  /* #endregion personalize */

  /* #region conheca */
  .conheca-container {
    padding: 30px 0;
  }

  .conheca-text-container p {
    font-size: 45px;
    margin-bottom: 20px;
  }


  /* #endregion conheca */

  /* #region cenarios */

  .cenarios-title-container h1 {
    width: 100%;
    padding: 80px 5px 0 10px;
  }

  .cenarios-title-container h1 {
    font-size: 40px;
  }

  .cenarios-content-container {
    font-size: 18px;
    flex-direction: column;
    width: 95%;
    margin: 20px auto 0 auto;
  }

  .cenarios-image-container img {
    width: 100%;
  }

  .cenarios-accordion-container {
    padding: 50px 0;
  }

  .accordion-header button {
    font-size: 18px;
  }

  /* #endregion cenarios */

  /* #region informe */

  .informe-container {
    padding: 30px 0;
  }

  .informe-text-container p {
    width: 100%;
    font-size: 30px;
    margin-bottom: 10px;
    padding: 0;
  }

  .informe-button-container button {
    font-size: 35px;
    padding: 0 30px;
  }

  /* #endregion informe */

  /* #region ajuda */

  .ajuda-form-container {
    flex-direction: column-reverse;
  }

  .ajuda-form {
    padding: 25px 20px;
    width: 100%;
  }

  .ajuda-form input {
    margin: 10px 0;
  }

  .ajuda-form h1 {
    color: #FFF;
    font-size: 22px;
  }

  .ajuda-contato {
    background-color: #FFF;
    padding: 10px 20px;
    width: 100%;
  }

  .ajuda-contato hr {
    width: 30%;
  }

  /* #endregion ajuda */

  /* #region footer */
  .footer {
    height: 400px;
    background-color: #000;
    position: relative;
  }

  .footer-logo {
    position: absolute;
    top: 30px;
    right: 0;
    width: 93%;
  }

  .footer-infos {
    flex-direction: column;
  }

  /* #endregion footer */
}

@media (max-width: 399.98px) {
  .contato-form-input {
    width: 55%;
  }

  .cenarios-title-container h1 {
    font-size: 35px;
  }

  .ajuda-form {
    padding: 30px 20px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {

  /* #region header-white */

  .header-white-container {
    height: 650px;
  }

  .header-white-container-text {
    padding-top: 15px;
    width: 90%;
  }

  .header-white-container-text p {
    font-size: 25px;
    text-align: start;
  }

  .header-white-image-container {
    width: 90%;
    bottom: 250px;
    border-bottom: 1px solid #2c2c2c;
  }

  .header-white-image-container img {
    width: 100%;
  }


  .header-white-contato-container {
    width: 100%;
    background-color: #efefef;
    top: 0;
    right: 0;
    transform: translateY(220px);
    border-radius: 5px;
    padding: 10px;
  }

  .contato-top {
    color: #605f5f;
    font-size: 18px;
    margin-bottom: 2px !important;
  }

  .contato-form-input {
    width: 62%;
  }

  .contato-bottom {
    font-size: 14px;
    width: 100%;
  }

  /* #endregion header-white */

  /* #region escolher */


  .escolher-title-container h1 {
    padding: 80px 5px 0 10px;
  }

  .escolher-title-container h1 {
    font-size: 45px;
  }

  .escolher-content-container {
    flex-direction: column;
    width: 95%;
  }

  .escolher-image-container {
    height: 100%;
    border-bottom: 1px solid #2c2c2c;
  }

  .escolher-image-container img {
    width: 100%;
  }

  .escolher-text-container {
    padding: 30px 20px 0 20px;
    font-size: 18px;
  }

  /* #endregion escolher */

  /* #region explore */


  .explore-title-container h1 {
    padding: 80px 5px 0 10px;
  }

  .explore-title-container h1 {
    font-size: 45px;
  }

  .explore-content-container {
    flex-direction: column;
    width: 95%;
    margin: 20px auto 0 auto;
  }

  .explore-image-container {
    height: 100%;
  }

  .explore-image-container img {
    width: 100%;
  }

  .explore-text-container {
    line-height: 1cm;
    padding: 30px 20px 0 20px;
    font-size: 18px;
  }

  /* #endregion explore */

  /* #region beneficio */

  .beneficio-title-container h1 {
    padding: 80px 5px 0 10px;
  }

  .beneficio-title-container h1 {
    font-size: 45px;
  }

  .beneficio-content-container {
    width: 95%;
    margin: 20px auto 0 auto;
  }

  .beneficio-text-container {
    line-height: normal;
    padding: 10px;
    font-size: 18px;
  }

  .beneficio-text-container .bottom-text {
    line-height: normal;
  }

  /* #endregion beneficio */

  /* #region versatilidade */
  .versatilidade-container {
    padding-top: 40px;
  }

  .versatilidade-container h1 {
    font-size: 45px;
    margin-bottom: 30px;
  }

  /* #endregion versatilidade */

  /* #region personalize */
  .personalize-container {
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
  }

  .personalize-text-container {
    width: 95%;
  }

  .personalize-text-container p {
    width: 100%;
    font-size: 35px;
  }

  .personalize-button-container {
    width: 95%;
  }

  .personalize-button-container button {
    width: 100%;
    font-size: 40px;
    padding: 0 10px;
  }

  /* #endregion personalize */

  /* #region conheca */

  .conheca-text-container p {
    font-size: 45px;
    margin-bottom: 20px;
  }


  /* #endregion conheca */

  /* #region cenarios */

  .cenarios-title-container h1 {
    width: 100%;
    padding: 80px 5px 0 10px;
  }

  .cenarios-title-container h1 {
    font-size: 40px;
  }

  .cenarios-content-container {
    font-size: 18px;
    flex-direction: column;
    width: 95%;
    margin: 20px auto 0 auto;
  }

  .cenarios-image-container img {
    width: 100%;
  }

  .cenarios-accordion-container {
    padding: 50px 0;
  }

  .accordion-header button {
    font-size: 18px;
  }

  /* #endregion cenarios */

  /* #region informe */

  .informe-container {
    padding: 30px 0;
  }

  .informe-text-container p {
    width: 100%;
    font-size: 30px;
    margin-bottom: 10px;
    padding: 0;
  }

  .informe-button-container button {
    font-size: 35px;
    padding: 0 30px;
  }

  /* #endregion informe */

  /* #region ajuda */

  .ajuda-form {
    padding: 50px 20px;
  }

  .ajuda-form h1 {
    color: #FFF;
    font-size: 20px;
  }

  .ajuda-contato {
    padding: 130px 5px;
  }

  .ajuda-contato hr {
    width: 30%;
  }

  /* #endregion ajuda */

  /* #region footer */
  .footer {
    height: 400px;
    background-color: #000;
    position: relative;
  }

  .footer-logo {
    position: absolute;
    top: 30px;
    right: 0;
    width: 93%;
  }

  .footer-infos {
    flex-direction: column;
  }

  /* #endregion footer */

}

@media (min-width: 768px) and (max-width: 991.98px) {

  /* #region header-white */
  .header-white-container-text {
    padding-top: 15px;
    width: 90%;
  }

  .header-white-container-text p {
    font-size: 25px;
    text-align: start;
  }

  .header-white-image-container {
    width: 90%;
  }

  .header-white-image-container img {
    width: 100%;
  }


  /* #endregion header-white */

  /* #region escolher */


  .escolher-title-container h1 {
    padding: 80px 5px 0 40px;
  }

  .escolher-title-container h1 {
    font-size: 45px;
  }

  .escolher-content-container {
    flex-direction: row;
    width: 95%;
  }

  .image-container {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .escolher-image-container {
    height: fit-content;
  }

  .escolher-image-container img {
    width: 100%;
  }

  .escolher-text-container {
    padding: 30px 5px 0 20px;
    width: 50%;
    font-size: 16px;
  }

  /* #endregion escolher */

  /* #region explore */


  .explore-title-container h1 {
    padding: 80px 5px 0 40px;
  }

  .explore-title-container h1 {
    font-size: 45px;
  }

  .explore-content-container {
    flex-direction: row;
    width: 95%;
    margin: 20px auto 0 auto;
  }

  .explore-image-container {
    height: fit-content;
  }

  .explore-image-container img {
    width: 100%;
  }

  .explore-text-container {
    width: 50%;
    line-height: inherit;
    padding: 30px 20px 0 5px;
    font-size: 16px;
  }

  /* #endregion explore */

  /* #region beneficio */

  .beneficio-title-container h1 {
    padding: 80px 5px 0 40px;
  }

  .beneficio-title-container h1 {
    font-size: 45px;
  }

  .beneficio-content-container {
    width: 95%;
    margin: 20px auto 0 auto;
  }

  .beneficio-text-container {
    line-height: normal;
    padding: 10px;
    font-size: 18px;
  }

  .beneficio-text-container .bottom-text {
    line-height: normal;
  }

  /* #endregion beneficio */

  /* #region versatilidade */
  .versatilidade-container {
    padding-top: 40px;
  }

  .versatilidade-container h1 {
    font-size: 45px;
    margin-bottom: 30px;
  }

  /* #endregion versatilidade */

  /* #region personalize */
  .personalize-container {
    flex-direction: row;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
  }

  .personalize-text-container {
    width: 50%;
  }

  .personalize-text-container p {
    width: 100%;
    font-size: 35px;
  }

  .personalize-button-container {
    width: 50%;
  }

  .personalize-button-container button {
    width: 90%;
    font-size: 35px;
    padding: 0 10px;
  }

  /* #endregion personalize */

  /* #region conheca */
  .conheca-container {
    padding: 30px 0;
  }

  .conheca-text-container p {
    font-size: 45px;
    margin-bottom: 20px;
  }


  /* #endregion conheca */

  /* #region cenarios */

  .cenarios-title-container h1 {
    width: 100%;
    padding: 80px 5px 0 40px;
  }

  .cenarios-title-container h1 {
    font-size: 40px;
  }

  .cenarios-content-container {
    font-size: 18px;
    flex-direction: column;
    width: 95%;
    margin: 20px auto 0 auto;
  }

  .cenarios-image-container img {
    width: 100%;
  }

  .cenarios-accordion-container {
    padding: 50px 0;
  }

  .accordion-header button {
    font-size: 18px;
  }

  /* #endregion cenarios */

  /* #region informe */

  .informe-container {
    padding: 30px 0;
  }

  .informe-text-container p {
    width: 100%;
    font-size: 30px;
    margin-bottom: 10px;
    padding: 0;
  }

  .informe-button-container button {
    font-size: 35px;
    padding: 0 30px;
  }

  /* #endregion informe */

  /* #region ajuda */

  .ajuda-form {
    padding: 50px 20px;
  }

  .ajuda-form input {
    margin: 12px 0;
  }

  .ajuda-form h1 {
    color: #FFF;
    font-size: 30px;
  }

  .ajuda-contato {
    padding: 130px 5px;
  }

  .ajuda-contato hr {
    width: 30%;
  }

  /* #endregion ajuda */

  /* #region footer */
  .footer {
    background-color: #000;
    position: relative;
  }

  .footer-logo {
    position: absolute;
    top: 30px;
    right: 0;
    width: 93%;
  }

  .footer-infos {
    flex-direction: column;
  }

  /* #endregion footer */
}

@media (min-width: 992px) and (max-width: 1199.98px) {

  /* #region header-white */
  .header-white-container-text p {
    font-size: 40px;
  }

  .header-white-image-container {
    width: 90%;
  }

  .header-white-image-container img {
    width: 100%;
  }

  /* #endregion header-white */

  /* #region escolher */


  .escolher-title-container h1 {
    padding: 80px 5px 0 100px;
  }

  .escolher-title-container h1 {
    font-size: 50px;
  }

  .escolher-content-container {
    flex-direction: row;
    width: 95%;
  }

  .image-container {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .escolher-image-container {
    height: fit-content;
  }

  .escolher-image-container img {
    width: 100%;
  }

  .escolher-text-container {
    padding: 30px 5px 0 20px;
    width: 50%;
    font-size: 16px;
  }

  /* #endregion escolher */

  /* #region explore */


  .explore-title-container h1 {
    padding: 80px 5px 0 100px;
  }

  .explore-title-container h1 {
    font-size: 50px;
  }

  .explore-content-container {
    flex-direction: row;
    width: 95%;
    margin: 20px auto 0 auto;
  }

  .explore-image-container {
    height: fit-content;
  }

  .explore-image-container img {
    width: 100%;
  }

  .explore-text-container {
    width: 50%;
    line-height: 1cm;
    padding: 30px 20px 0 5px;
    font-size: 16px;
  }

  /* #endregion explore */

  /* #region beneficio */

  .beneficio-title-container h1 {
    padding: 80px 5px 0 100px;
  }

  .beneficio-title-container h1 {
    font-size: 50px;
  }

  .beneficio-content-container {
    width: 95%;
    margin: 20px auto 0 auto;
  }

  .beneficio-text-container {
    line-height: normal;
    padding: 10px;
    font-size: 18px;
  }

  .beneficio-text-container .bottom-text {
    line-height: normal;
  }

  /* #endregion beneficio */

  /* #region versatilidade */
  .versatilidade-container {
    padding-top: 40px;
  }

  .versatilidade-container h1 {
    font-size: 45px;
    margin-bottom: 30px;
  }

  /* #endregion versatilidade */

  /* #region personalize */
  .personalize-text-container p {
    width: 80%;
    font-size: 45px;
  }

  .personalize-button-container {
    width: 50%;
  }

  .personalize-button-container button {

    font-size: 45px;
    padding: 0 10px;
  }

  /* #endregion personalize */

  /* #region cenarios */
  .cenarios-title-container h1 {
    width: 100%;
    padding: 80px 5px 0 100px;
  }

  .cenarios-title-container h1 {
    font-size: 50px;
  }

  .cenarios-content-container {
    font-size: 18px;
    flex-direction: row;
    width: 95%;
    margin: 20px auto 0 auto;
  }

  .cenarios-image-container {
    width: 50%;
  }

  .cenarios-image-container img {
    width: 100%;
  }

  .cenarios-accordion-container {
    width: 50%;
  }

  .accordion-header button {
    font-size: 18px;
  }

  /* #endregion cenarios */

  /* #region informe */
  .informe-text-container p {
    font-size: 40px;
  }

  /* #endregion informe */
}

@media (min-width: 1200px) and (max-width: 1399.98px) {

  /* #region header-white */
  .header-white-container-text {
    width: 90%;
    margin-bottom: 50px;
  }

  .header-white-container-text p {
    font-size: 50px;
  }

  .header-white-image-container {
    width: 90%;
  }

  .header-white-image-container img {
    width: 100%;
  }

  /* #endregion header-white */

  /* #region start-escolher */

  .escolher-content-container {
    width: 80%;
  }

  .escolher-title-container h1 {
    padding: 80px 5px 0 200px;
  }

  .escolher-title-container h1 {
    font-size: 55px;
  }

  .image-container {
    width: 50%;
    display: flex;
    align-items: flex-end;
  }

  .escolher-image-container {
    height: fit-content;
  }

  .escolher-image-container img {
    width: 100%;
  }

  .escolher-text-container {
    padding: 30px 5px 0 20px;
    width: 50%;
    font-size: 20px;
  }

  /* #endregion escolher */

  /* #region start-explore */

  .explore-content-container {
    width: 80%;
  }

  .explore-title-container h1 {
    padding: 80px 5px 0 200px;
  }

  .explore-title-container h1 {
    font-size: 55px;
  }

  .explore-image-container img {
    width: 100%;
  }

  .explore-text-container {
    width: 50%;
    line-height: 1cm;
    padding: 30px 20px 0 5px;
    font-size: 20px;
  }

  /* #endregion explore */

  /* #region start-beneficio */

  .beneficio-title-container h1 {
    padding: 80px 5px 0 200px;
  }

  .beneficio-title-container h1 {
    font-size: 55px;
  }

  .beneficio-content-container {
    width: 80%;
  }

  .beneficio-text-container {
    line-height: normal;
    padding: 15px;
    font-size: 20px;
  }

  .beneficio-text-container .bottom-text {
    line-height: normal;
  }

  /* #endregion beneficio */

  /* #region start-versatilidade */
  .versatilidade-container {
    padding-top: 40px;
  }

  .versatilidade-container h1 {
    font-size: 45px;
    margin-bottom: 30px;
  }

  /* #endregion versatilidade */

  /* #region start-cenarios */
  .cenarios-title-container h1 {
    width: 100%;
    padding: 80px 5px 0 200px;
  }

  .cenarios-title-container h1 {
    font-size: 55px;
  }

  .cenarios-content-container {
    font-size: 18px;
    flex-direction: row;
    width: 80%;
    margin: 20px auto 0 auto;
  }

  .cenarios-image-container {
    width: 50%;
  }

  .cenarios-image-container img {
    width: 100%;
  }

  .cenarios-accordion-container {
    width: 50%;
  }

  .accordion-header button {
    font-size: 18px;
  }

  /* #endregion cenarios */
}

/* #endregion media */