.header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.header-container img {
    width: 80%;
}

.header-container button {
    background-color: #56bd90;
    border: none;
    border-radius: 4px;
    font-size: 20px;
    font-weight: 500;
    padding: 5px 20px;
}

@media (max-width: 575.98px) {

    .header-container img {
        width: 50%;
    }

    .header-button-container {
        width: 50%;
    }

    .header-container button {
        font-size: 16px;
        padding: 2px 10px;
    }
}