.modal-dialog{
  max-width: 90vw;
}

.modal-dialog iframe{
  height: 315px;
}

@media (min-width:900px) {
  .modal-dialog{
    max-width: 50vw;
  }
  .modal-dialog iframe{
    height: 500px;
  }
}

.custom-modal .modal-content {
  background: transparent;
  border: none;
}

.custom-modal .modal-header,
.custom-modal .modal-body,
.custom-modal .modal-footer {
  background: transparent;
  border: none;
  padding: 20px 0 0 0;
}


/* #region header-red */
.header-red-container {
  background-color: #bf0316;
  padding: 30px 0;
}

.header-case-content-container {
  width: 80%;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
}

.header-case-text-container {
  width: 50%;
  font-size: 18px;
}

.header-case-image-container {
  width: 50%;
}

.header-case-image-container img {
  width: 50%;
}

/* #endregion header-red*/

/* #region revolucionando */
.revolucionando-container {
  height: fit-content;
}

.revolucionando-content-container {
  display: flex;
  flex-direction: row;
  padding: 40px 0 40px 200px;
}

.revolucionando-text-container {
  width: 35%;
}

.revolucionando-text-container h1 {
  font-size: 60px;
  margin-bottom: 50px;
}

.revolucionando-text-container p {
  font-size: 20px;
}

.revolucionando-text-container button {
  color: #FFF;
  padding: 5px 60px;
  border: none;
  background-color: #bf0316;
  font-size: 16px;
  border-radius: 4px;
}

.revolucionando-image-container {
  width: 65%;
}

.revolucionando-image-container img {
  width: 100%;
}

/* #endregion */

/* #region experiencia */
.experiencia-container {
  background-color: #efefef;
  height: fit-content;
  border-bottom: 1px solid #000000;
}

.experiencia-title-container h1 {
  color: #000;
  padding: 100px 0 0 300px;
  font-size: 60px;
}

.experiencia-content-container {
  font-family: Arial, Helvetica, sans-serif;
  color: #000;
  display: flex;
  flex-direction: row;
  width: 80%;
  margin: 20px auto 0 auto;
  justify-content: space-between;
  gap: 60px;
}

.experiencia-image-container {
  width: 100%;
  border-left: 1px solid #2c2c2c;
  border-right: 1px solid #2c2c2c;
  border-top: 50px solid #2c2c2c;
  border-radius: 20px 20px 0 0;
}

.experiencia-image-container img {
  width: 100%;
}

.experiencia-text-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 18px;
}

.experiencia-text-container p {
  margin-bottom: 0;
}

/* #endregion -experiencia */

/*  #region list */
.list-container {
  background-color: #efefef;
  height: fit-content;
  padding: 50px 0;
}

.list-content-container {
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: row;
  width: 80%;
  margin: 0 auto;
}

.list-text-container {
  padding: 50px 0;
  font-size: 18px;
}

.list-text-container p {
  margin-bottom: 0;
}

/*  #endregion list */

/*  #region funcionalidade */
.funcionalidade-container {
  background-color: #FFF;
  height: fit-content;
  border-bottom: 1px solid #000000;
}

.funcionalidade-title-container h1 {
  color: #000;
  padding: 100px 0 0 300px;
  font-size: 60px;
}

.funcionalidade-content-container {
  width: 80%;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  color: #000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 60px;
  margin: 48px auto 0 auto;
}

.funcionalidade-image-container {
  width: 100%;
  height: fit-content;
  border-left: 1px solid #2c2c2c;
  border-right: 1px solid #2c2c2c;
  border-top: 50px solid #2c2c2c;
  border-radius: 20px 20px 0 0;
}

.funcionalidade-image-container img {
  width: 100%;
}

.funcionalidade-text-container {
  width: 50%;
}

.funcionalidade-text-container p {
  margin-bottom: 50px;
}

/*  #endregion -funcionalidade */

/*  #region beneficios */

.beneficios-container {
  background-color: #56bd90;
  height: fit-content;
}

.beneficios-title-container h1 {
  width: 80%;
  color: #000;
  padding: 100px 0 0 300px;
  font-size: 60px;
}

.beneficios-content-container {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  color: #000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 80%;
  margin: 35px auto 0 auto;
  padding: 0 0 80px 0;
  gap: 60px;
}

.beneficios-image-container {
  height: fit-content;
  width: 100%;
  height: fit-content;
  border-left: 1px solid #2c2c2c;
  border-right: 1px solid #2c2c2c;
  border-bottom: 1px solid #2c2c2c;
  border-top: 50px solid #2c2c2c;
  border-radius: 20px 20px 0 0;
}

.beneficios-image-container img{
  width: 100%;
}

.beneficios-text-container {
  width: 50%;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.beneficios-text-container p {
  margin-bottom: 0;
}

/*  #endregion -beneficios */

/*  #region depoimento */
.depoimento-container {
  background-color: #660202;
  color: #FFF;
  padding: 40px 0;
}

.depoimento-content-container {
  width: 80%;
  margin: 0 auto;
}

.depoimento-title-container p {
  color: #FFF;
  font-size: 25px;
}

@media (min-width: 1400px) {
  .depoimento-text-container {
    font-size: 18px;
  }
}

/*  #endregion depoimento */

/*  #region personalize */
.personalize-container {
  height: fit-content;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #000000;
  padding: 30px 0;
}

.personalize-text-container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
}

.personalize-text-container p {
  width: 70%;
  font-size: 50px;
}

.personalize-button-container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.personalize-button-container button {
  background-color: #56bd90;
  border: none;
  border-radius: 4px;
  font-size: 50px;
  font-weight: 500;
  padding: 0 60px;
}

/*  #endregion personalize */

/* #region ------------------------------------------------------media---------------------------------------------- */

@media (max-width: 575.98px) {
  .modal-dialog{
    max-width: 100vw;
  }
  .modal-dialog iframe{
    height: 315px;
  }

  /* #region header-red */
  /* .header-red-container {
  background-color: #bf0316;
  padding: 30px 0;
} */

  /* .header-case-content-container {
  width: 98%;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
} */

  .header-case-text-container {
    /* width: 50%; */
    font-size: 14px;
  }

  /* .header-case-image-container {
  width: 50%;
} */

  .header-case-image-container img {
    width: 100%;
  }

  /* #endregion header-red*/

  /* #region revolucionando */
  /* .revolucionando-container {
  height: fit-content;
} */

  .revolucionando-content-container {
    /* display: flex; */
    flex-direction: column-reverse;
    padding: 40px 0;
  }

  .revolucionando-image-container {
    width: 98%;
    margin: 0 auto;
  }

  /* .revolucionando-image-container img {
  width: 100%;
} */

  .revolucionando-text-container {
    width: 98%;
    margin: 0 auto;
  }

  .revolucionando-text-container h1 {
    font-size: 40px;
    margin-bottom: 30px;
  }

  .revolucionando-text-container p {
    font-size: 20px;
    margin-bottom: 40px;

  }

  .revolucionando-text-container button {
    color: #FFF;
    padding: 5px 60px;
    border: none;
    background-color: #bf0316;
    font-size: 16px;
  }



  /* #endregion */

  /* #region experiencia */

  .experiencia-title-container h1 {
    padding: 50px 5px 0 10px;
  }

  .experiencia-title-container h1 {
    font-size: 45px;
  }

  .experiencia-content-container {
    flex-direction: column;
    width: 98%;
  }

  .experiencia-image-container {
    height: fit-content;
    border-bottom: 1px solid #2c2c2c;
  }

  .experiencia-image-container img {
    width: 100%;
  }

  .experiencia-text-container {
    padding: 30px 0;
    font-size: 18px;
    width: 100%;
  }

  /* #endregion-experiencia */

  /* #region list */

  .list-content-container {
    width: 98%;
    margin: 0 auto;
  }

  .list-text-container {

    line-height: normal;
    padding: 0;
    font-size: 18px;
  }

  .list-text-container .bottom-text {
    line-height: normal;
  }

  /* #endregion -list */

  /* #region funcionalidade */
  .funcionalidade-title-container h1 {
    padding: 50px 5px 0 10px;
  }

  .funcionalidade-title-container h1 {
    font-size: 45px;
  }

  .funcionalidade-content-container {
    flex-direction: column;
    width: 98%;
    margin: 0 auto;
  }

  .funcionalidade-image-container {
    height: 100%;
  }

  .funcionalidade-image-container img {
    width: 100%;
  }

  .funcionalidade-text-container {
    width: 100%;
    padding: 20px 0;
    font-size: 18px;
  }

  /* #endregion-funcionalidade */

  /* #region beneficios */

  .beneficios-title-container h1 {
    width: 100%;
    padding: 50px 5px 0 10px;
  }

  .beneficios-title-container h1 {
    font-size: 45px;
  }

  .beneficios-content-container {
    font-size: 18px;
    flex-direction: column;
    width: 98%;
  }

  .beneficios-image-container img {
    width: 100%;
  }

  .beneficios-text-container {
    width: 100%;
    padding: 40px 0 0 0;
    font-size: 18px;
  }

  /* #endregion -beneficios */

  /*  #region depoimento */

  /*  #endregion depoimento */

  /* #region personalize */
  .personalize-container {
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
  }

  .personalize-text-container {
    width: 95%;
  }

  .personalize-text-container p {
    width: 100%;
    font-size: 35px;
  }

  .personalize-button-container {
    width: 95%;
  }

  .personalize-button-container button {
    width: 100%;
    font-size: 40px;
    padding: 0 10px;
  }

  /* #endregion -personalize */

}

@media (min-width: 576px) and (max-width: 767.98px) {

  /* #region header-red */
  /* .header-red-container {
  background-color: #bf0316;
  padding: 30px 0;
} */

  /* .header-case-content-container {
  width: 98%;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
} */

  .header-case-text-container {
    /* width: 50%; */
    font-size: 14px;
  }

  /* .header-case-image-container {
width: 50%;
} */

  .header-case-image-container img {
    width: 100%;
  }

  /* #endregion header-red*/

  /* #region revolucionando */
  .revolucionando-content-container {
    flex-direction: column-reverse;
    padding: 40px 0;
  }

  .revolucionando-image-container {
    width: 98%;
    margin: 0 auto;
  }

  .revolucionando-text-container {
    width: 95%;
    margin: 0 auto;
  }

  .revolucionando-text-container h1 {
    font-size: 40px;
    margin-bottom: 30px;
  }

  .revolucionando-text-container p {
    font-size: 20px;
    margin-bottom: 40px;

  }

  .revolucionando-text-container button {
    color: #FFF;
    padding: 5px 60px;
    border: none;
    background-color: #bf0316;
    font-size: 16px;
  }



  /* #endregion */

  /* #region experiencia */
  .experiencia-title-container h1 {
    padding: 50px 5px 0 10px;
    font-size: 45px;
  }

  .experiencia-content-container {
    flex-direction: column;
    width: 95%;
  }

  .experiencia-image-container {
    height: 100%;
    border-bottom: 1px solid #2c2c2c;
  }

  .experiencia-image-container img {
    width: 100%;
  }

  .experiencia-text-container {
    width: 100%;
    padding: 30px 0;
    font-size: 18px;
  }

  /* #endregion-experiencia */

  /* #region list */

  .list-content-container {
    width: 98%;
    margin: 0 auto;
  }

  .list-text-container {
    line-height: normal;
    font-size: 18px;
  }

  .list-text-container .bottom-text {
    line-height: normal;
  }

  /* #endregion -list */

  /* #region funcionalidade */

  .funcionalidade-title-container h1 {
    padding: 50px 5px 0 10px;
  }

  .funcionalidade-title-container h1 {
    font-size: 45px;
  }

  .funcionalidade-content-container {
    flex-direction: column;
    width: 95%;
    margin: 0 auto;
  }

  .funcionalidade-image-container {
    height: 100%;
  }

  .funcionalidade-image-container img {
    width: 100%;
  }

  .funcionalidade-text-container {
    width: 100%;
    line-height: 1cm;
    padding: 30px 0;
    font-size: 18px;
  }

  /* #endregion-funcionalidade */

  /* #region beneficios */

  .beneficios-title-container h1 {
    width: 100%;
    padding: 50px 5px 0 10px;
    font-size: 45px;
  }

  .beneficios-content-container {
    font-size: 18px;
    flex-direction: column;
    width: 95%;
    margin: 20px auto 0 auto;
    padding: 0;
  }

  .beneficios-image-container {
    width: 100%;
  }

  .beneficios-image-container img {
    width: 100%;
  }

  .beneficios-text-container {
    width: 100%;
    padding: 20px 0;
  }

  /* #endregion -beneficios */

  /* #region depoimento */
  /*  #endregion depoimento */

  /* #region personalize */
  .personalize-container {
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
  }

  .personalize-text-container {
    width: 95%;
  }

  .personalize-text-container p {
    width: 100%;
    font-size: 35px;
  }

  .personalize-button-container {
    width: 95%;
  }

  .personalize-button-container button {
    width: 100%;
    font-size: 40px;
    padding: 0 10px;
  }

  /* #endregion -personalize */

}

@media (min-width: 768px) and (max-width: 991.98px) {

  /* #region header-red */
  .header-case-text-container {
    font-size: 14px;
  }

  .header-case-image-container img {
    width: 100%;
  }

  /* #endregion header-red*/

  /* #region revolucionando */
  .revolucionando-content-container {
    flex-direction: column-reverse;
    padding: 40px 0;
  }

  .revolucionando-image-container {
    width: 98%;
    margin: 0 auto;
  }

  .revolucionando-text-container {
    width: 95%;
    margin: 0 auto;
  }

  .revolucionando-text-container h1 {
    font-size: 40px;
    margin-bottom: 30px;
  }

  .revolucionando-text-container p {
    font-size: 20px;
    margin-bottom: 40px;

  }

  .revolucionando-text-container button {
    color: #FFF;
    padding: 5px 60px;
    border: none;
    background-color: #bf0316;
    font-size: 16px;
  }



  /* #endregion */

  /* #region experiencia */
  .experiencia-title-container h1 {
    padding: 50px 5px 0 40px;
    font-size: 45px;
  }

  .experiencia-content-container {
    flex-direction: row;
    width: 95%;
  }

  .image-container {
    width: 50%;
    justify-content: center;
  }

  .experiencia-image-container {
    height: fit-content;
  }

  .experiencia-image-container img {
    width: 100%;
  }

  .experiencia-text-container {
    padding: 30px 5px 0 20px;
    width: 50%;
    font-size: 14px;
  }

  /* #endregion-experiencia */

  /* #region list */
  .list-content-container {
    width: 95%;
    margin: 20px auto 0 auto;
  }

  .list-text-container {
    line-height: normal;
    padding: 0;
    font-size: 18px;
  }


  /* #endregion -list */

  /* #region funcionalidade */
  .funcionalidade-title-container h1 {
    padding: 50px 5px 0 40px;
  }

  .funcionalidade-title-container h1 {
    font-size: 45px;
  }

  .funcionalidade-content-container {
    flex-direction: row;
    width: 95%;
    margin: 0 auto;
  }

  .funcionalidade-image-container {
    height: fit-content;
  }

  .funcionalidade-image-container img {
    width: 100%;
  }

  .funcionalidade-text-container {
    width: 50%;
    line-height: inherit;
    padding: 30px 20px 0 5px;
    font-size: 14px;
  }

  .funcionalidade-text-container p {
    margin-bottom: 20px;
  }

  /* #endregion-funcionalidade */

  /* #region beneficios */

  .beneficios-title-container h1 {
    width: 100%;
    padding: 50px 5px 0 40px;
  }

  .beneficios-title-container h1 {
    font-size: 45px;
  }

  .beneficios-content-container {
    flex-direction: row;
    width: 95%;
    margin: 20px auto 0 auto;
    padding: 0 0 60px 0;
  }

  .beneficios-image-container img {
    width: 100%;
  }

  .beneficios-text-container {
    padding: 10px;
    font-size: 14px;
  }

  /* #endregion -beneficios */

  /* #region depoimento */
  /*  #endregion depoimento */

  /* #region personalize */
  .personalize-container {
    flex-direction: row;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
  }

  .personalize-text-container {
    width: 50%;
  }

  .personalize-text-container p {
    width: 100%;
    font-size: 35px;
  }

  .personalize-button-container {
    width: 50%;
  }

  .personalize-button-container button {
    width: 90%;
    font-size: 35px;
    padding: 0 10px;
  }

  /* #endregion -personalize */


}

@media (min-width: 992px) and (max-width: 1199.98px) {

  /* #region header-red */
  .header-case-text-container {
    font-size: 16px;
  }

  .header-case-image-container img {
    width: 60%;
  }

  /* #endregion header-red*/

  /* #region revolucionando */
  .revolucionando-content-container {
    padding: 40px 0;
  }

  .revolucionando-image-container {
    width: 98%;
    display: flex;
    align-items: center;
    margin: 0 auto;
  }

  .revolucionando-text-container {
    width: 95%;
    padding: 0 20px;
  }

  .revolucionando-text-container h1 {
    font-size: 40px;
    margin-bottom: 30px;
  }

  .revolucionando-text-container p {
    font-size: 18px;
    margin-bottom: 40px;

  }

  .revolucionando-text-container button {
    color: #FFF;
    padding: 5px 60px;
    border: none;
    background-color: #bf0316;
    font-size: 16px;
  }



  /* #endregion */

  /* #region experiencia */
  .experiencia-title-container h1 {
    padding: 80px 5px 0 100px;
  }

  .experiencia-title-container h1 {
    font-size: 50px;
  }

  .experiencia-content-container {
    flex-direction: row;
    width: 95%;
  }

  .image-container {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .experiencia-image-container {
    height: fit-content;
  }

  .experiencia-image-container img {
    width: 100%;
  }

  .experiencia-text-container {
    padding: 30px 5px 0 20px;
    width: 50%;
    font-size: 16px;
  }

  /* #endregion-experiencia */

  /* #region list */
  .list-content-container {
    width: 95%;
    margin: 0 auto;
  }

  .list-text-container {
    line-height: normal;
    padding: 0;
    font-size: 16px;
  }

  /* #endregion -list */

  /* #region funcionalidade */
  .funcionalidade-title-container h1 {
    padding: 80px 5px 0 100px;
    font-size: 50px;
  }

  .funcionalidade-content-container {
    flex-direction: row;
    width: 95%;
    margin: 0 auto;
  }

  .image-container {
    display: flex;
    align-items: flex-end;
  }

  .funcionalidade-image-container {
    height: fit-content;
  }

  .funcionalidade-image-container img {
    width: 100%;
  }

  .funcionalidade-text-container {
    width: 50%;
    line-height: normal;
    padding: 30px 20px 0 5px;
    font-size: 16px;
  }

  /* #endregion-funcionalidade */

  /* #region beneficios */
  .beneficios-title-container h1 {
    width: 100%;
    padding: 80px 5px 0 100px;
    font-size: 50px;
  }

  .beneficios-content-container {
    flex-direction: row;
    width: 95%;
    margin: 20px auto 0 auto;
  }

  .beneficios-image-container {
    width: 100%;
  }

  .beneficios-image-container img {
    width: 100%;
  }

  .beneficios-text-container {
    padding: 40px 0 0 40px;
    font-size: 16px;
  }

  /* #endregion -beneficios */

  /* #region depoimento */
  /*  #endregion depoimento */

  /* #region personalize */
  .personalize-text-container p {
    width: 80%;
    font-size: 45px;
  }

  .personalize-button-container {
    width: 50%;
  }

  .personalize-button-container button {

    font-size: 45px;
    padding: 0 10px;
  }

  /* #endregion -personalize */

}

@media (min-width: 1200px) and (max-width: 1399.98px) {

  /* #region header-red */
  .header-case-text-container {
    font-size: 16px;
  }

  .header-case-image-container img {
    width: 70%;
  }

  /* #endregion header-red*/

  /* #region revolucionando */
  .revolucionando-content-container {
    padding: 40px 0;
  }

  .revolucionando-image-container {
    width: 98%;
    display: flex;
    align-items: center;
    margin: 0 auto;
  }

  .revolucionando-text-container {
    width: 95%;
    padding: 0 20px;
  }

  .revolucionando-text-container h1 {
    font-size: 40px;
    margin-bottom: 30px;
  }

  .revolucionando-text-container p {
    font-size: 18px;
    margin-bottom: 40px;

  }

  .revolucionando-text-container button {
    color: #FFF;
    padding: 5px 60px;
    border: none;
    background-color: #bf0316;
    font-size: 16px;
  }



  /* #endregion */

  /* #region start-experiencia */

  .experiencia-content-container {
    width: 80%;
  }

  .experiencia-title-container h1 {
    padding: 80px 5px 0 200px;
    font-size: 55px;
  }

  .image-container {
    width: 50%;
    display: flex;
    align-items: flex-end;
  }

  .experiencia-image-container {
    height: fit-content;
  }

  .experiencia-image-container img {
    width: 100%;
  }

  .experiencia-text-container {
    padding: 30px 5px 0 20px;
    width: 50%;
    font-size: 18px;
  }

  /* #endregion-experiencia */

  /* #region start-list */

  .list-content-container {
    width: 80%;
  }

  .list-text-container {
    line-height: normal;
    padding: 0;
    font-size: 18px;
  }

  /* #endregion -list */

  /* #region start-funcionalidade */

  .funcionalidade-content-container {
    width: 80%;
  }

  .funcionalidade-title-container h1 {
    padding: 80px 5px 0 200px;
  }

  .funcionalidade-title-container h1 {
    font-size: 55px;
  }

  .funcionalidade-image-container img {
    width: 100%;
  }

  .funcionalidade-text-container {
    width: 50%;
    line-height: normal;
    padding: 30px 20px 0 5px;
    font-size: 18px;
  }

  /* #endregion-funcionalidade */

  /* #region start-beneficios */
  .beneficios-title-container h1 {
    width: 100%;
    padding: 80px 5px 0 200px;
  }

  .beneficios-title-container h1 {
    font-size: 55px;
  }

  .beneficios-content-container {
    font-size: 18px;
    flex-direction: row;
    width: 80%;
    margin: 20px auto 0 auto;
  }

  .beneficios-image-container {
    width: 100%;
  }

  .beneficios-image-container img {
    width: 100%;
  }

  .beneficios-text-container {
    font-size: 18px;
  }

  /* #endregion -beneficios */
}

/* #endregion media*/